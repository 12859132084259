@tailwind base;
@tailwind components;
@tailwind utilities;

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

html {
  @apply !scroll-smooth;
}

body {
  @apply flex min-h-screen flex-col bg-yellow-50 text-gray-800 antialiased;
  overscroll-behavior: none;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
}

section {
  scroll-margin-top: 80px;
}

a,
input,
button {
  @apply outline-none;
}

a,
button,
[role='button'] {
  -webkit-tap-highlight-color: transparent;
}

@layer components {
  .container-fluid {
    @apply mx-auto w-full max-w-[1400px] px-6 lg:px-12 xl:px-20 2xl:max-w-screen-3xl 2xl:px-32;
  }
}

@layer utilities {
  .text-stroke {
    color: transparent;
    -webkit-text-stroke: 2px rgb(230 209 184 / 0.2);
  }
}
